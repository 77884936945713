import { useUserStore } from "~/stores/user";
import maybeBypassAuthMiddlewareRoutes from "~/utils/maybeBypassAuthMiddlewareRoutes";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    if (maybeBypassAuthMiddlewareRoutes(to)) {
      return; // Bypass the middleware
    }

    const userStore = useUserStore();
    const { getSteps } = useOnboarding();

    if (userStore.getAccount && userStore.getAccount.onboarding_step >= getSteps().length) {
      return;
    } else {
      return navigateTo('/account-onboarding')
    }
  }
});



